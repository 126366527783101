import React from "react";
import './FourthSection.css';


function FourthSection() {
  return (
    <div className="fourth-section-outer-container collapse">
      <div className="fourth-section-inner-container">
        <div className="fourth-section-box-1">
      <h3 className="banner-heading">ENGINEERED FOR GAMERS</h3>
    </div>
    </div>
    </div>
  );
}

export default FourthSection;
