import React from "react";
import './ImageSection.css';


function ImageSection() {
  return (
    
    <div className="image-section-outer-container collapse">
    <img className="image-section-image" src="/final-image.png"></img>
    </div>
    
  );
}

export default ImageSection;
